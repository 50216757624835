<template>
    <div id="eventsList" class="events_view">
        <div class="events_view-inner">
            <ul class="list list--calendar">
                <template v-for="(events, day) in groupedList" :key="day">
                    <li class="list-item list-item--header" v-if="! date">{{ $filters.formatDate(day, 'j. F Y') }}</li>
                    <li class="list-item" v-for="evt in events" :key="evt.uid">
                        <ToggleBox :item="evt" />
                    </li>
                </template>
            </ul>
        </div>
    </div>
</template>

<script>

import EventsBus from '../../utils/EventsBus';
import ToggleBox from '../Subparts/ToggleBox';

export default {
	name: "KappEventsList",
	components: {
		ToggleBox
	},
	data() {
		return {
			dayView: true,
			isVisible: false,
			selectedDay: new Date(),
			eventsList: this.events
		};
	},
	props: {
		events: {
			type: Array,
			required: true
		},
		date: {
			type: [Date,Boolean]
		}
	},
	computed: {
		groupedList: function () {
			let list = {};
			this.eventsList.forEach(( item ) => {
				let date = new Date(item.start * 1000);
				date.resetTo('hour');
				let idx = date.getTime() / 1000;
				if ( false === Object.prototype.hasOwnProperty.call(list, idx) ) {
					list[ idx ] = [];
				}
				list[ idx ].push(item);
			}, list);

			return list;
		}
	},
	methods: {
		open: function ( searchResults ) {
			this.dayView = !searchResults;
			this.isVisible = true;

			this.$nextTick(() => EventsBus.trigger('overlay:open'));

		},
		close: function () {
			this.isVisible = false;
		}
	},
	created() {
		this.eventsList = this.events;
	},

	watch: {
		events: function ( val ) {
			this.eventsList = val;
		},
		date: function ( val ) {
			this.selectedDay = val;
		}
	}
};
</script>

<style scoped>

</style>
