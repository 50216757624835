<template>
    <div>
        <ul class="downloads">
            <li class="downloads-item" v-for="(file,idx) in files" :key="idx">
                <a :href="file.url" @click="showInIframe(file.url, $event )"><i class="icon-download"></i> <span>{{ file.title }}</span>
                    <small>({{ file.size }})</small>
                </a>
                <!--<router-link :to="{ name : 'Download', params: {file: file.url } }" v-show="false" ><i class="icon-download"></i>
					<span>{{file.title | ucfirst}}</span>
					<small>({{ file.size | prettyBytes}})</small>
				</router-link>-->
            </li>
        </ul>
    </div>

</template>

<script>
// import FileDownload from "../plugins/FileDownload";

export default {
	name: "Downloadlist",
	data() {
		return {
			showFile: false,
			fileToShow: false
		};
	},
	components: {
		// 'download' : FileDownload
	},
	props: [ 'files' ],
	methods: {
		showInIframe: function ( url, evt ) {
			evt.preventDefault();
			// this.fileToShow = url;
			// Events.$emit( 'modal:open', url );
		}
	},
	filters: {
		ucfirst: function ( str ) {
			if ( !str ) return '';
			str = str.toString();
			return str.charAt(0).toUpperCase() + str.slice(1);
		},
		prettyBytes: function ( num ) {
			// jacked from: https://github.com/sindresorhus/pretty-bytes
			if ( typeof num !== 'number' || isNaN(num) ) {
				throw new TypeError('Expected a number');
			}

			var exponent;
			var unit;
			var neg = num < 0;
			var units = [ 'B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB' ];

			if ( neg ) {
				num = -num;
			}

			if ( num < 1 ) {
				return (neg ? '-' : '') + num + ' B';
			}

			exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
			num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
			unit = units[ exponent ];

			return (neg ? '-' : '') + num + ' ' + unit;
		}
	},
	created() {
		// Events.$on('modal:close', () => this.fileToShow = false );
	}
};
</script>

<style scoped>
.downloads {
    margin: 15px 0 0;
    padding-left: 0;
    list-style: inside none;
}

.downloads-item {
    padding: 4px 0 4px 32px;
}

.downloads-item + .downloads-item {
    margin-top: 5px;
}

a {
    font-weight: 700;
    color: #A1AAB3;
    text-decoration: none;
    width: 100%;
}

.app--light a {
    color: #394452;
}

a span {
    -webkit-hyphens: auto;
    hyphens: auto;
    word-break: break-all;
}

a i[class*=icon] {
    margin-right: 5px;
    font-size: 20px;
    vertical-align: middle;
    margin-left: -37px;
}

a small {
    font-weight: 400;
    display: inline;
    white-space: nowrap;
}
</style>
