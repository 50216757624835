<template>
    <div id="kalender">
        <teleport to="#sidebar" :disabled="eventsView === 'mobile'">
        <div class="calendar-list">
            <KappKalenderMonth v-for="month in showMonths" :id="'month-' + month" v-bind:key="month" :month="month"
                               :events="calendarEvents" @dayopen="openDay" :selected-day="selectedDay" />
        </div>
        </teleport>
        <Lightbox v-if="eventsView === 'mobile'" :is-visible="showEvents" @close="showEvents=false">
            <template v-slot:content>
                <template v-if="selectedDay">
                    <header class="events_view-header">
                        <p class="h2">Kalender</p>
                        <h1 class="header-title">{{ $filters.formatDate(this.selectedDay, 'j. F Y') }}</h1>
                    </header>
                </template>
                <span :title="$t('create_new_event')" class="button button--primary button--big m-b-1"  v-if="isAdmin" @click="showForm"><inline-svg :src="require('@/assets/images/ic_new.svg')" width="24" class="icon" />Neuer Eintrag</span>
                <KappEventsList v-if="selectedEvents.length > 0" :events="selectedEvents" :date="selectedDay" ref="eventsList" />
                <div v-else>
                    <h3>{{$t('no_events')}}</h3>
                </div>
            </template>
        </Lightbox>
        <div class="calendar-events" v-else-if="eventsView === 'desktop' ">
            <template v-if="selectedDay">
                <header class="events_view-header">
                    <p class="h2">Kalender</p>
                    <h1 class="header-title">{{ $filters.formatDate(selectedDay, 'j. F Y') }}</h1>
                </header>
            </template>
            <span :title="$t('create_new_event')" v-if="isAdmin" class="button button--primary button--big m-b-1" @click="showForm('create')"><inline-svg :src="require('@/assets/images/ic_new.svg')" width="24" class="icon" />Neuer Eintrag</span>
            <KappEventsList v-if="selectedEvents.length > 0" :events="selectedEvents" :date="selectedDay" ref="eventsList" @openEdit="formOpen=true" />
            <div v-else>
                <h3>{{$t('no_events')}}</h3>
            </div>
        </div>

        <Lightbox :is-visible="formOpen" @close="formOpen = false;">
            <template v-slot:content><KappPostsNew :doktype="3" :date="selectedDay" :action="formAction" :data="formData" /></template>
        </Lightbox>
        <ReloadButton @reload="fetchData" type="events" />
    </div>
</template>

<script>

import api, { ApiDefaults } from "@/utils/api_call";
import KappKalenderMonth from '@/components/Blocks/KappKalenderMonth';
import debounce from 'lodash.debounce';
import KappEventsList from '@/components/Blocks/KappEventsList';
import { STORE_EVENTS } from '@/utils/store/actions/general';
// import NewEventForm from './NewEventForm';
import { mapGetters } from 'vuex';
import ReloadButton from '../KappMain/ReloadButton';
// import Lightbox from '@/components/Plugins/Lightbox';
// import KappPostsNew from '@/pages/Posts/KappPostsNew';
import { defineAsyncComponent } from 'vue';
import EventsBus from '@/utils/EventsBus';

export default {
	name: "KappKalender",
	components: {
		KappKalenderMonth,
		KappEventsList,
		// NewEventForm,
		ReloadButton,
		KappPostsNew: defineAsyncComponent(()=>import('@/pages/Posts/KappPostsNew.vue')),
		Lightbox : defineAsyncComponent(()=>import('@/components/Plugins/Lightbox.vue'))
	},
	data() {
		return {
			pageTitle: 'Kalender',
			categories: [],
			visibleMonths: 12,
			isLoading: true,
			categoryFilter: '',
			fullTextFilter: '',
			showSearchResults: false,
			selectedDay: new Date(),
			// selectedEvents: [],
			showEvents: false,
            formOpen: false,
            formAction: 'create',
            formData: false,
		};
	},
	methods: {
		showForm: function( action = 'create') {
			this.formOpen = true;
			this.formAction = action;
        },
        showEditForm: function(event){
			this.formData = event;
			this.showForm('update');
        },
		fetchData: debounce(function () {
			let apiParams = Object.assign({}, ApiDefaults, {params: {id: 6}});
			api(apiParams)
				.then(( response ) => {

					if ( response.status === 200 ) {
						if ( Object.hasOwnProperty.call(response.data, 'news') ) {

							this.events = response.data.news.content.map(function ( evt ) {
								evt.color = '#004F95'; // set Default value
								return evt;
							});

							this.$store.commit(STORE_EVENTS, this.events);

							this.categories = response.data.news.categories;
						}
					}
				})
				.catch(( err ) => {
					console.warn(err.message);
				})
				.finally(() => {
					this.isLoading = false;
				});
		}, 125),
		openDay: function ( forDay ) {
			// let events = this.events;
			this.selectedDay = new Date(forDay.tstamp * 1000);
			// this.selectedEvents = events.filter(( evt ) => {
			// 	let start = new Date(evt.start * 1000),
            //         end = new Date(evt.end * 1000 );
			// 	if ( this.selectedDay.isBetween(start, end, 'day') ) {
			// 		return true;
			// 	}
			// });
            this.showEvents = true;
		}
	},
	computed: {
		selectedEvents: function() {
            if ( this.selectedDay ) {
                return this.calendarEvents.filter(( evt ) => {
                    let start = new Date(evt.start * 1000),
                        end = new Date(evt.end * 1000);
                    if ( this.selectedDay.isBetween(start, end, 'day') ) {
                        return true;
                    }
                });
            }
            else if ( window.matchMedia('(min-width: 1024px)').matches ) {
                let d = new Date();
                return this.calendarEvents.filter(( item ) => {
                    let eDate = new Date();
                    eDate.setTime(item.start * 1000);
                    return eDate.isSame(d, 'month');
                }).sort(( a, b ) => a.start - b.start);
            }

			return false;
        },
		eventsView: function(){
			return window.matchMedia('(max-width:1023px)').matches ? 'mobile' : 'desktop';
		},
		filteredEvents: function () {
			let evts = this.calendarEvents;
			return evts.filter(evt => {
				let category = this.categoryFilter,
					textFilter = this.fullTextFilter,
					inCategory = true;

				if ( category ) {
					category = category.split("-");
					inCategory = (evt.categories === category[ 1 ]);
				}

				return inCategory && evt.title.toLowerCase().includes(textFilter.toLowerCase());
			});
		},
		startMonth: function () {
			let d = new Date();
			return (d.getMonth() + 1);// Januar = 0, also wird durh weitere scripte der eine vorhaerige Monat mit angezeigt
		},
		showMonths: function () {
			return Array(this.visibleMonths).fill(this.startMonth).map(( x, y ) => x + y);
		},
        ...mapGetters(['calendarEvents','isAdmin'])
	},
	created() {
		this.fetchData();
	},
	mounted() {
		EventsBus.on('editEvent',this.showEditForm);
	},
	watch: {
		showMonths: function () {
			// this.$nextTick ( function() {
			this.$scrollTo('.month.is-actual', 10, {container: '.content-wrap', offset: -145, duration: 0});
			// });
		}
	}
};
</script>

<style scoped>


</style>
