<template>
    <div class="month" :class="{ 'is-actual' : actualMonth }">
        <h2 v-if="showYear" class="year">{{ year }}</h2>
        <h3 class="month-name" :class="'week_start-' + startWeekDay">{{ monthName }}</h3>
        <table>
            <tr class="week" v-for="(week,key) in weeks" v-bind:key="key">
                <td class="day" :data-week="week.nr" v-for="(day,kd) in week.days" v-bind:key="kd" :class="day.type" @click="$emit('dayopen',day)">
                    <span class="day-value">{{ day.val }}</span>
                    <KappKalenderEvent v-if="day.type !== 'fueller' && eventsForDay(day.tstamp).length"
                            :events="eventsForDay(day.tstamp)"
                            :tstamp="day.tstamp" />
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import KappKalenderEvent from "../Subparts/KappKalenderEvent";
import { mapGetters } from 'vuex';

export default {
	name: "KappKalenderMonth",
	props: {
		month : {
			type: Number
        },
        events : {
			type: Array
        },
        selectedDay : {
			type: [Date,Boolean],
            default: false
        }
    },
	components: {
		KappKalenderEvent
	},
	data() {
		return {
			today: new Date(),
			showDayData: false
		};
	},
	computed: {
		...mapGetters(['eventsForDay']),
		showYear() {
			return ((this.month % 12) === 1);
		},
		year() {
			return this.monthDate.getFullYear();
		},
		actualMonth() {
			let today = new Date();
			return ( this.monthDate.getMonth() === today.getMonth() )
		},
		monthDate() {
			let date = new Date();
			date.setDate(1);
			date.setMonth(this.month - 1);

			return date;
		},

		monthName: function () {
			return this.monthDate.toLocaleString('de-de', {month: 'long'});
		},

		startWeekDay: function () {
			return parseInt(this.monthDate.getDay()) - 1;
		},

		lastDay: function () {
			let date = new Date(this.today.getFullYear(), this.month, 0);
			return date.getDate();
		},

		weekCnt: function () {
			let dayCnt = this.lastDay + this.startWeekDay;

			return Math.ceil(dayCnt / 7);
		},

		weeks: function () {

			let weeks = [],
				monthDays = 0,
				weekCnt = this.weekCnt;

			for ( let w = 0; w < weekCnt; w++ ) {

				let week = {
					nr: (new Date(this.today.getFullYear(), this.month - 1, (monthDays + 1)).getWeek()),
					days: []
				};

				let x = (w == 0 ? this.startWeekDay : 0);

				// erste Woche des Monats. Beinhaltet die fueller Tage des vorherigen Monats
				if ( w == 0 ) {

					if ( x < 0 ) {
						x = x + 7;
						weekCnt++;
					}

					for ( let wd = 0; wd < x; wd++ ) {
						let type = [ 'fueller' ];

						if ( wd == 5 ) // Samstag ist noch vorheriger Monat
							type.push('is-weekend');

						week.days.push({type: type, val: ' ', tstamp: false});
					}
				}

				// Regulaeren Monatstage
				for ( let d = x; d < 7; d++ ) {
					if ( monthDays < this.lastDay ) {
						let date = new Date(this.today.getFullYear(), this.month - 1, monthDays + 1, 12, 0),
							type = [ 'regular' ];

						if ( date.isSame(this.today, 'day') ) {
							type.push('is-today');
						}

						if( this.selectedDay)
						if( date.isSame(this.selectedDay, 'day')){
							type.push('is-active');
                        }

						if ( date.getDay() === 0 || date.getDay() === 6 )
							type.push('is-weekend');
						week.days.push({type: type, val: monthDays + 1, tstamp: date.getTime() / 1000});
					}
					monthDays++;
				}
				weeks.push(week);
			}
			return weeks;
		}

	},
	methods: {
	}
};
</script>

<style scoped>
</style>
